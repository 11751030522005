body, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, .navbar, .brand, .btn-simple, .alert, a, .td-name, td, button.close {
  font-family: 'Kanit', Arial, sans-serif;
}
body, body > .navbar-collapse .nav li .nav-link p,
.sidebar .nav li .nav-link p,
.sidebar .nav .nav-link, body > .navbar-collapse .nav .nav-link,
.btn, .card label, .table > thead > tr > th, .form-control {
  font-size: 14px;
}
.table > thead > tr > th {
  text-align: center !important;
  font-weight: bold;
  color: inherit;
}
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 8px !important;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  margin: inherit;
}
h5 { font-size: 14px; }

.text-success, .text-success:hover { color: #05C31B !important; }
.fa, .fas, .far, .fal, .fad, .fab { width: auto; }

.navbar { background: none !important; border: none; }
.sidebar .logo .simple-text, body > .navbar-collapse .logo .simple-text { text-transform: none; font-weight: bold; color: #000; }
.sidebar .logo, body > .navbar-collapse .logo { border-bottom: none; }
.sidebar .logo .simple-text, body > .navbar-collapse .logo .simple-text { font-size: 24px; }
.sidebar::before { opacity: 1; background: #fff; }
.sidebar::after { display: none; }
.sidebar .nav li .nav-link, body > .navbar-collapse .nav li .nav-link { color: #000; }
.sidebar .nav li.active .nav-link, body > .navbar-collapse .nav li.active .nav-link { color: #000; background: rgba(170, 170, 170, 0.23); }
.sidebar .nav li:hover .nav-link, body > .navbar-collapse .nav li:hover .nav-link { background: rgba(170, 170, 170, 0.23); opacity: 1; }
.sidebar .nav li .nav-link p, body > .navbar-collapse .nav li .nav-link p { text-transform: none; }
.sidebar .nav li .nav-link i, body > .navbar-collapse .nav li .nav-link i { font-size: 20px; line-height: 31px; margin-right: 8px; }
.card label { text-transform: none; }
.modal.show .modal-dialog { transform: none; }
.modal .modal-content .modal-header { padding-top: 10px; padding-bottom: 10px; background: #49B4C1; color: #fff; }

.btn-success { border-color: #11B3C1; }
.btn-success.btn-fill { background-color: #11B3C1; }
.btn-success.btn-fill:hover, .btn-success.btn-fill:focus, .btn-success.btn-fill:active, .btn-success.btn-fill.active, .open > .btn-success.btn-fill.dropdown-toggle { background-color: #11A1AD; }
.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .open > .btn-success.dropdown-toggle { border-color: #11A1AD; }
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle { background-color: #11B3C1; border-color: #11B3C1; }
.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus { box-shadow: 0 0 0 .2rem rgba(17,179,193,.5); }

.badge { font-size: 100%; font-weight: 500; }
.badge-warning { color: #fff; }

.react-datepicker .anticon { vertical-align: middle; }
.react-datepicker button, .react-datepicker select { border: solid 1px #aeaeae; border-radius: 0.3rem; background: #e0e0e0; }
.react-datepicker__day--outside-month { color: #bbb !important; }
.react-datepicker-wrapper { display: block !important; }

.btn-manage-group .btn-manage:not(:first-child) { margin-left: 5px; }
.btn-manage-group .btn-manage:not(:first-child)::before { content: "|"; margin-right: 5px; }

.color-upbean { color: #01c0c8; }
.i-ud { font-size: 18px; }
.i-xxl { font-size: 80px; width: auto; }

.steps { display: flex; list-style: none; background: #09B3C1; margin: 0; padding: 0; }
.steps > .step { text-align: center; padding: 15px; position: relative; }
.steps > .step.active { color: #fff; }
.steps > .step::after { content: ""; display: block; width: 100%; height: 2px; position: absolute; right: 50%; top: 50%; background: #212529; }
.steps > .step.active::after { background: #fff; }
.steps > .step:first-child::after { display: none; }
.steps > .step .step-icon { font-size: 24px; width: 38px; vertical-align: middle; background: #09B3C1; position: relative; z-index: 1; }
.steps > .step .step-content { vertical-align: middle; background: #09B3C1; position: relative; z-index: 1; padding-right: 10px; }

.form-label-r .col-form-label { text-align: right; }
.upload-file-name::after {content: "อัปโหลดไฟล์";  }

.z-top { z-index: 10000; }

.drop-pdf { position: relative; width: fit-content; margin: 0 auto; }
.draggable { user-select: none; cursor: move; }
.drag-pdf-item {
  font-family: 'Sarabun'; font-size: 16px;
  display: flex; background: #FDA1A1; border: solid 1px #707070; text-align: center; justify-content: center; align-content: center; flex-wrap: wrap;
  margin: 0 !important; min-width: 100px; min-height: 30px;
}
.drag-pdf-item > i { display: none; }
.drag-pdf-item.text { width: 100px; }
.drag-pdf-item.runno, .drag-pdf-item.year { justify-content: left; align-content: baseline; }
.drag-pdf-item.date { align-content: baseline; }
.drag-pdf-item.choice { justify-content: left; align-content: baseline; text-align: left; }
.drag-pdf-item.choice .pl { padding-left: 30px; }

.sidebar-r { position: relative; }
.sidebar-r .sidebar-content { margin-right: 250px; padding: 15px; background: #F5F9FB; overflow: auto; }
.sidebar-r .sidebar-content.draging { overflow: visible; }
.sidebar-r .sidebar-wrap { position: absolute; top: 0; right: 0; width: 250px; height: 100%; padding: 15px; background: #D9F5F7; }
.sidebar-r .sidebar-wrap .draggable { cursor: move !important; }

.box-paper { border: solid 1px #ccc; box-shadow: 0px 0px 3px #ccc; }

.box-sign-wrap { position: relative; }
.box-sign-mark { position: absolute; top: 0; left: 0; right: 0; line-height: 200px; font-size: 30px; text-align: center; opacity: 0.2; user-select: none; }
.box-sign { position: relative; border: solid 1px #707070; }
.box-sign-icon { font-size: 100px; width: 100px; line-height: unset; }

.page-no { display: inline-block; min-width: 100px; border: solid 1px #ccc; padding: 9px; vertical-align: middle; }
.hide { visibility: hidden; }
.flex-break { flex-basis: 100%; }

.dashboard-user .card .card-footer { min-height: 51px; }